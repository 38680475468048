* {
    font-family: 'Times New Roman', Times, serif;
    margin: 0;
    padding: 10px;
    color: white;
  }
  
  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }
  
  .section-title {
    position: relative;
  }
  
  .section-title::before {
    margin: auto;
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: White;
    bottom: 0;
    left: 0;
    transform-origin: center;
    transform: scaleX(0);
    transition: transform 0.1s ease-in-out;
  }
  
  .section-title:hover::before {
    margin: auto;
    transform-origin: center;
    transform: scaleX(1);
  }
  
  .section-title {
    font-size: 1.5em;
    width: fit-content;
    display: inline-block;
  }
  
  .container {
    position: relative;
  }
  
  .content {
    position: relative;
    z-index: 1;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0.0;
    }
  }
  
  .main-header {
    height: 100px;
    text-align: center;
    padding: 0;
    margin: 3rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10vh;
    position: relative;
    font-size: 3rem;
  }
  
  .name-title {
    height: 200;
    text-align: center;
  }
  
  .skills-list {
    text-align: center;
    width: 20rem;
  }
  
  .container {
    text-align: center;
  }
  
  .title {
    text-align: center;
  }

  body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main-header span {
    position: relative;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    animation: waviy 1s infinite;
    animation-delay: calc(0.1s * var(--i));
  }

  

  
  
  @keyframes waviy {
    0%,
    40%,
    100% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(-20px);
    }
  }
  